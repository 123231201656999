/** @format */

const TOOLTIP = require('./templates/tooltip.html');
const TOOLTIP_INDICATOR = require('./templates/indicator.html');

export default class TooltipService {
  static create(indicators, type) {
    let template = TooltipService.getTemplate(type);
    let markup = indicators.map(TooltipService.indicatorMarkup);

    return template.replace('###indicators###', markup.join(''));
  }

  static indicatorMarkup(i) {
    let sanitatize = val => (val !== undefined && val !== null ? val : '');

    return TOOLTIP_INDICATOR.replace(/\${(\w+)}/gi, (_, b) => {
      return sanitatize(i[b]);
    });
  }

  static getTemplate(type = '') {
    return TOOLTIP.replace('${cssClass}', type);
  }
}
