/** @format */

import { Alert } from 'services/api/alertsService';

export class BrodascastUserMessage {
  constructor(data) {
    Object.assign(this, data);
  }
}

export class UserHub {
  constructor(hubProxy, onMessage) {
    if (onMessage) {
      hubProxy.on('broadcastAlert', data => {
        onMessage('broadcastAlert', new Alert(data));
      });

      hubProxy.on('broadcastMessage', data => {
        onMessage('broadcastMessage', new BrodascastUserMessage(data));
      });
    }
  }
}
