/** @format */

import eventService, { EventsList } from 'services/eventService';
import analyticsService from 'services/analyticsService';

import { apiConfig } from 'configs';
import LOG from 'services/loggerServices';

import { hubConnection, signalR } from 'signalr-no-jquery';
import { UserHub } from './hubs/userHub';
import { LiveHub } from './hubs/liveHub';

// //HOTFIX: URL being wrongly built
// //https://github.com/SignalR/SignalR/issues/3776
// //https://github.com/SignalR/SignalR/issues/3776#issuecomment-259469385

var getUrl = signalR.transports._logic.getUrl;
// prettier-ignore
signalR.transports._logic.getUrl = function (connection,transport,reconnecting,poll,ajaxPost) {
  var url = getUrl(connection,transport,reconnecting,poll,ajaxPost);
  url = connection.url + url.substring(url.indexOf(connection.appRelativeUrl) + connection.appRelativeUrl.length);
  if (transport === "webSockets") {
    url = url.substring(url.indexOf(connection.appRelativeUrl));
  }
  return url;
};

// prettier-ignore
const HubFactory = (name,conn,onMessage) => {
  const hubs = {
    'user': (hubProxy) => { return new UserHub(hubProxy, onMessage) },
    'live': (hubProxy) => { return new LiveHub(hubProxy, onMessage) },
  }

  const hub = conn.createHubProxy(`${name}Hub`);
  return hubs[name] ? hubs[name](hub) : hub;
}

const BASE_URL = apiConfig.domain;
const ConnectionStates = {
  slow: 'slow',
  reconnecting: 'reconnecting',
  disconnected: 'disconnected',
  reconnected: 'reconnected',
  closed: 'closed'
};

export class SignalrService {
  constructor() {
    this.connection;
    this.hubs = [];
    this.connected = false;
  }

  get apiToken() {
    return this.apiService.token;
  }

  get connectionState() {
    return Object.keys(ConnectionStates)[(this.connection || {}).state];
  }

  initConnection(token) {
    if (!this.connection) {
      this.connection = hubConnection(BASE_URL);

      this.connection.logging = false;
      this.connection.qs = `token=${token}`;

      this.connection.connectionSlow(
        this.onConnectionStatus.bind(this, ConnectionStates.slow)
      );
      this.connection.reconnecting(
        this.onConnectionStatus.bind(this, ConnectionStates.reconnecting)
      );
      this.connection.reconnected(
        this.onConnectionStatus.bind(this, ConnectionStates.reconnected)
      );
      this.connection.disconnected(
        this.onConnectionStatus.bind(this, ConnectionStates.disconnected)
      );
    }
  }

  onConnectionStatus(state) {
    switch (state) {
      case ConnectionStates.disconnected:
        this.reconnect();
        break;
    }

    let err = this.connection.lastError;
    LOG.warn(
      `SignalrService: connection: ${state} ${
        err ? `|  lastError: ${err}` : ''
      }`
    );
  }

  reconnect(tries = 30) {
    if (!this.reconnecting) {
      let t = 0;
      this.reconnecting = true;
      const tryConn = (ms = 10000) => {
        t++;

        if (t % 5 === 0) {
          this.remoteLog('connection problems', {
            tried: t,
            state: this.connectionState
          });
        }
        if (t <= tries) {
          setTimeout(() => {
            LOG.info(`SignalrService: trying to reconnect.. ${t} times`);
            this.connect(null)
              .then(() => (this.reconnecting = false))
              .catch(() => tryConn());
          }, ms);
          return;
        }
        this.reconnecting = false;
      };
      tryConn(0);
    }
  }

  connect(token) {
    return new Promise((resolve, reject) => {
      //init new connection
      this.initConnection(token);

      //auto register hubs
      this.registerHub('user');
      this.registerHub('live');

      const onConn = ok => {
        let connStr = `SignalrService: connection ${
          ok ? 'started' : 'failed'
        } ${((this.connection || {}).transport || {}).name}`;
        if (!ok) {
          LOG.error(connStr);
          reject();
          return;
        }

        LOG.info(connStr);
        resolve();
      };
      // let opc = { transport: 'longPolling' };
      this.connection
        .start({ withCredentials: false })
        .done(() => onConn(true))
        .fail(() => onConn(false));
    });
  }

  disconnect() {
    if (this.connection) {
      this.connection.stop();
    }
  }

  registerHub(name) {
    if (this.connected) {
      LOG.error(
        'Cannot connect a hub while connection is open',
        name,
        this.connection.id
      );
      return;
    }

    if (!this.hubs[name]) {
      this.hubs[name] = HubFactory(
        name,
        this.connection,
        this.onHubMessage.bind(this)
      );
    }
    return this.hubs[name];
  }

  //As of now all hubs are dumb, event based, they are on all the time and fire an event to every listener.
  //Of we need more granular control to maybe turn them .off(), instead of event based we can expose, on and off here
  onHubMessage(name, ...params) {
    LOG.debug('SignalrService: onHubMessage:', name, ...params);
    //broardcast event
    let evtName = EventsList[`Signalr${name}`];
    if (evtName) {
      eventService.publish(evtName, ...params);
      return;
    }
    LOG.warn('Signalr event not valid!', `Signalr${name}`);
  }

  remoteLog(message, data) {
    analyticsService.trackError('SignalrService', message, data, 'info');
  }
}

const signalrService = new SignalrService();

export default signalrService;
