export const STORAGE_KEYS = {
  APPVERSION_KEY_NAME:'fonix-appversion',
  IMPERSONATE_KEY_NAME:'fonix-impersonate',
  WHATSNEW_KEY_PREFIX:'fonix-whatsew'
}
import LOG from './loggerServices';

class MemoryStorage {
  constructor() {
    this.data = {};
    LOG.warn('MemoryStorage was instanciated. Maybe because localStorage is not available')
  }

  getItem(key) {
    return this.data[key]
  }

  set(key,value) {
    if (key) {
      this.data[key] = value;
    }
  }

  remove(key) {
    this.data[key] = null;
  }

  clear() {
    this.data = {};
  }
}


let instance = null;
class StorageService {

  constructor() {
    if(instance) {
      return instance;
    }
    instance = this;  

    this.storage = 'localStorage' in window && window.localStorage ? window.localStorage : new MemoryStorage();
  }

  get(key) {
    let value = this.storage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  set(key,value) {
    try {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    }
    catch (e) { 
      //noop
    }

    return false;
  }

  remove(key) {
    return this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
    return true;
  }

}
let storageService = new StorageService();
export default storageService;
