/** @format */

const MapEvents = {
  zoomChanged: 'zoomChanged',
  markerClick: 'markerClick',
  zoneClick: 'zoneClick',
  mapClick: 'mapClick',
  mapDrawEnd: 'mapDrawEnd',
  mapMarkerMouseover: 'mapMarkerMouseover',
  mapMarkerMouseout: 'mapMarkerMouseout',
  mapZoneMouseover: 'mapZoneMouseover',
  mapZoneMouseout: 'mapZoneMouseout'
};

export default MapEvents;
