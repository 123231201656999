/** @format */

export class SnapshotUpdate {
  constructor(data) {
    Object.assign(this, data);
  }
}

export class LiveHub {
  constructor(hubProxy, onMessage) {
    if (onMessage) {
      hubProxy.on('broadcastSnapshot', data => {
        onMessage('broadcastSnapshot', new SnapshotUpdate(data));
      });
    }
  }
}
