/** @format */
import { I18N } from 'aurelia-i18n';
import eventService, { EventsList } from 'services/eventService';

/**
 * The translation service wrapper for i18n
 *
 * @see https://github.com/aurelia/i18n
 */
export class TranslationService {
  static inject() {
    return [I18N];
  }

  /**
   * constrtor for the Service
   * @param {class} I18N from 'aurelia-i18n' plugin
   */
  constructor(_I18N) {
    this.i18n = _I18N;
  }

  /**
   * translate key from i18n instance
   * @param {string} key translatation key
   *
   * for more see https://github.com/aurelia/i18n
   *
   * @return {...string} the translation or key if not found
   */
  get(...params) {
    return this.i18n.tr(...params);
  }

  getCap(...params) {
    return this._capitalize(this.get(...params));
  }

  /**
   * Loads a translations namespace from backend
   * @param {string | Array} names - namespace name(s)
   *
   * for more see http://i18next.com/docs/api/#load-namespaces
   *
   * @return {Promise} after the namespace is loaded
   */
  loadNamespaces(names) {
    // this.i18n
    return new Promise((resolve, reject) => {
      this.i18n.i18next.loadNamespaces(names, errs => {
        if (errs) {
          reject(false);
        } else {
          //let the world know!
          eventService.publish(EventsList.I18NLoaded);

          //signals binding beahviours to update
          // this.bindingSignaler.signal('aurelia-translation-signal');

          resolve(true);
        }
      });
    });
  }

  _capitalize(str = '') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
