/** @format */

import { AssetStates } from 'services/api/assetsService';
import { TelemetryEvents } from 'services/api/models/Telemetry';

const MapIcons = new Map();

const cdnURL = name =>
  `https://cdn.fonixcloud.com/web/map/${name}.png`;

// const url
MapIcons.set('start', cdnURL('flag'));
MapIcons.set('car', cdnURL('car'));
MapIcons.set(`car_${AssetStates.stopped}`, cdnURL('car_stopped'));
MapIcons.set(`car_${AssetStates.idling}`, cdnURL('car_idle'));
MapIcons.set(`car_${AssetStates.travelling}`, cdnURL('car_travelling'));
MapIcons.set(`inJourney`, cdnURL('car_travelling'));
MapIcons.set('waypoint', cdnURL('arrow-up'));
MapIcons.set(TelemetryEvents.ignitionOn, cdnURL('key'));
MapIcons.set(TelemetryEvents.ignitionOff, cdnURL('key_red'));
MapIcons.set('location', cdnURL('target'));
MapIcons.set('poi', cdnURL('location2'));
MapIcons.set('poileave', cdnURL('location2'));
MapIcons.set('poienter', cdnURL('location2'));
MapIcons.set('zones', cdnURL('target'));
MapIcons.set('alert', cdnURL('caution'));
MapIcons.set('overspeed', cdnURL('overspeed_red'));
MapIcons.set(TelemetryEvents.ecoEvent, cdnURL('ecodriving-arrow'));
MapIcons.set(TelemetryEvents.parked, cdnURL('parked'));
MapIcons.set(TelemetryEvents.idling, cdnURL('arrow-up-yellow'));
MapIcons.set('poi_cluster', cdnURL('poicluster'));
MapIcons.set('asset_cluster', cdnURL('assetcluster'));

//

MapIcons.set('mobile', cdnURL('smartphone'));
MapIcons.set(`mobile_${AssetStates.stopped}`, cdnURL('smartphone_stopped'));
MapIcons.set(`mobile_${AssetStates.idling}`, cdnURL('smartphone_idle'));
MapIcons.set(
  `mobile_${AssetStates.travelling}`,
  cdnURL('smartphone_travelling')
);

MapIcons.set('type_road', cdnURL('layer_road'));
MapIcons.set('type_satellite', cdnURL('layer_satellite'));
MapIcons.set('type_terrain', cdnURL('layer_terrain'));
MapIcons.set('layer_places', cdnURL('type_places'));
MapIcons.set('layer_zones', cdnURL('type_zones'));
MapIcons.set('layer_traffic', cdnURL('type_traffic'));


export default MapIcons;
