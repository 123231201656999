/** @format */
import {
  transient
} from 'aurelia-framework';
import {
  DateTimeUtils,
  ConvertUtils
} from '@fonix/web-utils';
import Utils from 'utils/utils';

import {
  AssetTypes
} from 'services/api/assetsService';
import {
  AlertTypes
} from 'services/api/alertsService';

import geoService from 'services/api/geoService';
import googleService from 'services/googleService';
import LOG from 'services/loggerServices';
import {
  TranslationService
} from 'services/translationService';

import {
  TelemetryEvents
} from 'services/api/models/Telemetry';
import uiService from 'services/uiService';
import eventService, {
  EventsList
} from 'services/eventService';
import storageService from 'services/storageService';

import MapEvents from 'services/map/mapEvents';
import TooltipService from 'services/map/tooltipService';
import MapIcons from 'services/map/mapIcons';

export const MapStyles = {
  trail: {
    weight: 3.5,
    colors: {
      default: '#727272',

      //check _vars.scss
      R: '#fb8c00',
      G: '#8bc34a',
      B: '#2196f3',
      O: '#e53935'
    }
  }
};

export const MapProviders = {
  google: 'google',
  osm: 'osm'
};

//
const LayerGroups = {
  trip: 'trips',
  waypoints: 'waypoint',
  parked: 'parked',
  idling: 'idling',
  fleet: 'fleet',
  poi: 'poi',
  zones: 'zones',
  shapes: 'shapes',
  route: 'route',
  alert: 'alert'
};

export const TelemetryModes = {
  trip: 'trip',
  alert: 'alert'
};

const MAP_LOCALSTORAGE_KEYS = {
  activeLayers: 'mapservice-activelayers'
};

@transient()
export class MapService {
  static inject() {
    return [TranslationService];
  }

  constructor(_TranslationService) {
    this._translationService = _TranslationService;
    //
    this.geoService = geoService;
    this.uiService = uiService;
    this._eventService = eventService;
    this.storageService = storageService;
    //

    this.onRequestShapes = this.onRequestShapes.bind(this);
    this.onMapContextAddessSelected = this.onMapContextAddessSelected.bind(this)

    this.instance = null;
  }

  create(domEl, provider, options) {
    return new Promise((resolve, reject) => {
      if (!domEl) {
        return reject('DOM container element not provided for map');
      }

      const onCreated = instance => {
        resolve(instance);
        this.onInstanceCreated(instance);
      };

      //factory
      //required google depedencies and create map
      if (provider === MapProviders.google) {
        const loadG = googleMaps => {
          new googleMaps(domEl, options).then(onCreated);
        };

        return require.ensure(
          [],
          function (require) {
            loadG(require('services/map/providers/google/googleMap').default);
          },
          'googleMap'
        );
      }

      //require leaflet depedencies and create map
      if (provider === MapProviders.osm) {
        const loadL = leafletMap => {
          new leafletMap(domEl, options, provider).then(onCreated);
        };

        return require.ensure(
          [],
          function (require) {
            loadL(require('services/map/providers/leaflet/leafletMap').default);
          },
          'leafletMap'
        );
      }

      return reject('Map provider not valid!');
    });
  }

  onInstanceCreated(instance) {
    this.instance = instance;

    //attach events
    this.attachEvents();
    this.currentTileLayer = {};
    this.currentActiveLayers =
      this.storageService.get(MAP_LOCALSTORAGE_KEYS.activeLayers) || {};
    this.changeMapTiles('default');

    this.getMapLayers().forEach(layer => {
      if (this.currentActiveLayers[layer.id]) {
        this.setMapLayer(layer, true);
      }
    });

    this.ready = true;

    return Promise.resolve(instance);
  }

  resize() {
    if (this._hasImpl('resize')) {
      this.instance.resize();
    }
  }

  setBoundsPadding(boundsPadding) {
    if (this._hasImpl('setBoundsPadding')) {
      this.instance.setBoundsPadding(boundsPadding);
    }
  }

  destroy() {
    this._eventService.unsubscribe(
      EventsList.MapLocationMarker,
      this.onMapContextAddessSelected
    );
    
    this._eventService.unsubscribe(
      EventsList.MapServiceRequestShapes,
      this.onRequestShapes
    );

    if (this._hasImpl('destroy')) {
      this.instance.destroy();
    }

    this.ready = false;
  }

  attachEvents() {
    if (this._hasImpl('listenEvent')) {
      this.instance.listenEvent(
        MapEvents.zoomChanged,
        this.onZoomChanged.bind(this)
      );
      this.instance.listenEvent(
        MapEvents.markerClick,
        this.onMarkerClick.bind(this)
      );
      this.instance.listenEvent(
        MapEvents.zoneClick,
        this.onZoneClick.bind(this)
      );
      this.instance.listenEvent(
        MapEvents.mapDrawEnd,
        this.onDrawEnd.bind(this)
      );
      this.instance.listenEvent(
        MapEvents.mapMarkerMouseover,
        this.onMapMarkerMouseover.bind(this)
      );
      this.instance.listenEvent(
        MapEvents.mapMarkerMouseout,
        this.onMapMarkerMouseout.bind(this)
      );
      this.instance.listenEvent(
        MapEvents.mapZoneMouseover,
        this.onMapZoneMouseover.bind(this)
      );
      this.instance.listenEvent(
        MapEvents.mapZoneMouseout,
        this.onMapZoneMouseout.bind(this)
      );
    }

    this._eventService.subscribe(
      EventsList.MapLocationMarker,
      this.onMapContextAddessSelected
    );
    this._eventService.subscribe(
      EventsList.MapServiceRequestShapes,
      this.onRequestShapes
    );
  }

  onMapContextAddessSelected(address) {
    this.setLocationMarker(address ? address.latlng : null);
  }

  //event somewhere else
  onRequestShapes(clearCurrent) {
    this.getShapes(clearCurrent).then(shapes => {
      this._eventService.publish(EventsList.MapServiceShapes, shapes);
    });
  }

  //TODO: refactor into a better way to relay mapevents from mapservice into map component
  // (using listenEvent method and mapEvents ?)
  onClick(callback) {
    this.instance.listenEvent(MapEvents.mapClick, callback);
  }

  onDrawEnd(shapes) {
    let shape = shapes.length ? shapes[0] : shapes;
    this._eventService.publish(EventsList.MapServiceDrawEnd, shape);
  }

  onMarkerClick(item) {
    if (item) {
      let events = {
        fleet: EventsList.MapServiceAssetSelected,
        poi: EventsList.MapServicePOISelected
      };
      let event = events[item.type];
      if (event) {
        this._eventService.publish(event, item.data);
      }
    }
  }

  onZoneClick(item) {
    if (item) {
      let events = {
        zones: EventsList.MapServiceZoneSelected
      };
      let event = events[item.type];
      if (event) {
        this._eventService.publish(event, item.data);
      }
    }
  }

  onMapMarkerMouseover(marker) {
    this.currentTooltipMarker = marker;

    if (this.uiService.userSettings.mapTooltipAddress && marker && marker.metadata) {
      if (
        marker.metadata.type === 'fleet' &&
        !marker.metadata.data.address &&
        marker.metadata.data.location
      ) {
        return new Promise(res => {
          setTimeout(() => {
            if (this.currentTooltipMarker) {
              return this.updateTooltipMarkerAddress(
                this.currentTooltipMarker
              ).then(marker => {
                if (marker && this.currentTooltipMarker) {
                  let stillActive =
                    this.currentTooltipMarker.metadata.data.id ===
                    marker.metadata.data.id;
                  return res(stillActive ? marker.tooltip : null);
                }
                return res(null);
              });
            }
            return res(null);
          }, 250);
        });
      }
    }
  }

  onMapZoneMouseover(zone) {
    this.currentTooltipMarker = zone;
  }

  updateTooltipMarkerAddress(marker) {
    if (!marker) {
      return Promise.resolve(null);
    }
    let data = marker.metadata.data;
    let [lat, lng] = data.latlng;
    return this.geoService.reverseGeoCoding(lat, lng).then(address => {
      data.location = data.location || {};
      data.location.address = address;

      marker.tooltip = this.renderTooltip(data, marker.metadata.type);
      return marker;
    });
  }

  onMapMarkerMouseout() {
    this.currentTooltipMarker = null;
  }

  onMapZoneMouseout() {
    this.currentTooltipMarker = null;
  }

  onZoomChanged(zoom) {
    if (this._hasImpl('toggleLayerGroup')) {
      this.instance.toggleLayerGroup(LayerGroups.waypoints, zoom > 11);
    }

    this._eventService.publish(EventsList.MapServiceZoomChanged, zoom);
  }

  shouldCluster(numMarkers, minMarkers, clusterNum = 0) {
    return (
      numMarkers > minMarkers && clusterNum >= 0 && numMarkers >= clusterNum
    );
  }

  setFleet(assets, fitMap = true, cluster) {
    assets = assets || [];
    //set markers
    if (this._hasImpl('setMarkers')) {
      let validAssets = assets.filter(a => a.latlng);
      let _cluster = this.shouldCluster(validAssets.length, 5, cluster);

      //delete old first
      this.instance.setMarkers(null, LayerGroups.fleet, true);

      let process = part => {
        let markers = part.map(p => this.convertAssetToMarker(p));
        this.instance.setMarkers(
          markers,
          LayerGroups.fleet,
          false,
          true,
          _cluster
        );
        return markers;
      };
      if (fitMap) this.fitMapMarkers(validAssets);

      Utils.arrayAsyncProcess(validAssets, process, 100);
    }
  }

  updateFleet(assets = []) {
    assets.forEach(a => {
      let options = this.convertAssetToMarker(a);
      this.instance.updateMarker(a.id, LayerGroups.fleet, options);
    });
  }

  setTelemetry(trip, autoFit = true, options) {
    trip = trip || [];
    options = options || {};

    let mode = TelemetryModes[options.mode] || TelemetryModes.trip;
    let showTooltips = !(options.tooltips === false); //only if false value.
    let simpleTrail = options.simpleTrail;
    let tripMode = mode === TelemetryModes.trip;
    let boundMarkers = [];

    //calc all trip info details (waypoints etc)
    let tripDetails = this.calcTripsDetails(trip);

    if (tripMode) {
      //find last trip (last since ign on) of all trip info
      tripDetails = this.findLastTripEvent(tripDetails);
    }

    //Set markers
    if (this._hasImpl('setMarkers')) {
      let convertToMarker = (_m, i) => {
        //refactor this somehow..
        let rotate =
          _m.heading &&
          (!_m.events || _m.firstEventId === TelemetryEvents.ecoEvent || _m.firstEventId === TelemetryEvents.idling);
        return {
          latlng: _m.latlng,
          options: {
            icon: this.getMapIcon(_m, i),
            rotation: rotate ? _m.heading : null,
            opacity: _m.oldTrip ? 0.9 : 1,
            clickable: true,
            zIndex: 999
          },
          // metadata: _m,
          tooltip: showTooltips ? this.renderTooltip(_m, 'trail') : null
        };
      };

      //waypoint markers
      let wps = [].concat.apply([], tripDetails.map(t => t.waypoints));
      let waypointMarkers = wps.filter(wp => !wp.parked && !wp.alert);
      let alertMarkers = wps.filter(wp => !!wp.alert);
      let parkedMarkers = wps.filter(wp => !!wp.parked);

      this.instance.setMarkers(
        waypointMarkers.map(convertToMarker),
        LayerGroups.waypoints,
        true
      );
      this.instance.setMarkers(
        parkedMarkers.map(convertToMarker),
        LayerGroups.parked,
        true
      );
      this.instance.setMarkers(
        alertMarkers.map(convertToMarker),
        LayerGroups.alert,
        true
      );

      //first, last markers
      let flMarkers = [];
      if (trip.length > 0) {
        flMarkers.push(trip[0]);
        flMarkers[0]._name = 'start';
        flMarkers[0]._alert = options.alert;
        flMarkers[0].heading = null;

        if (tripMode) {
          flMarkers.push(trip[trip.length - 1]);
          flMarkers[1]._name = 'end';
          flMarkers[1].heading = null;
        }
      }

      this.instance.setMarkers(
        flMarkers.map(convertToMarker),
        LayerGroups.trip,
        true
      );

      boundMarkers = flMarkers.concat(waypointMarkers);
    }

    //set polyline
    if (this._hasImpl('setPolyline')) {
      let weight = MapStyles.trail.weight - (simpleTrail ? 1 : 0);

      let convertToPolyline = _t => {
        let weightFactor = _t.oldTrip ? 2 : 1;
        let color = simpleTrail ?
          MapStyles.trail.colors.default :
          MapStyles.trail.colors[_t.level];
        return {
          latlngs: _t.latlngs,
          options: {
            color: color,
            weight: weight / weightFactor,
            lineCap: 'round',
            opacity: 0.8,
            zIndex: 99
          }
        };
      };

      this.instance.setPolylines(
        tripDetails.map(convertToPolyline),
        `${LayerGroups.trip}-line`,
        true
      );
    }

    if (autoFit) {
      setTimeout(() => {
        this.fitMapMarkers(boundMarkers);
      }, 500);
    }
  }

  setPOI(poi, cluster) {
    let pois = poi || [];

    let convertToPOIMarker = p => {
      let [lng, lat] = p.center;
      return {
        latlng: [lat, lng],
        options: {
          icon: {
            url: MapIcons.get('poi'),
            size: [16, 16]
          },
          clickable: true,
          zIndex: 1
        },
        tooltip: this.renderTooltip(p, 'poi'),
        metadata: {
          type: 'poi',
          data: p
        }
      };
    };

    let _cluster = this.shouldCluster(poi.length, 20, cluster);

    //remove old first, process wont run, if array is empty
    this.instance.setMarkers(null, LayerGroups.poi, true);

    let process = part => {
      let poiMarkers = part.map(convertToPOIMarker);
      this.instance.setMarkers(
        poiMarkers,
        LayerGroups.poi,
        false,
        false,
        _cluster
      );
    };

    Utils.arrayAsyncProcess(pois, process.bind(this), 500).then(() => {
      if (this.currentActiveLayers[LayerGroups.poi] === true) {
        this.instance.toggleLayerGroup(LayerGroups.poi, true);
      }
    });
  }

  //sets geojson zones on map
  setZones(zones, deleteCurrent = true, fitBounds = false) {
    let _zones = zones || [];
    _zones = _zones.map(z => {
      z.tooltip = this.renderTooltip(z, 'zones')
      return z;
    })
    this.instance.setZones(_zones, LayerGroups.zones, false, deleteCurrent, fitBounds);

    let process = part => {
      let _zones = part || [];
      this.instance.setZones(
        _zones,
        LayerGroups.zones,
        false,
        deleteCurrent,
        fitBounds
      );
    };

    Utils.arrayAsyncProcess(_zones, process.bind(this), 500).then(() => {
      this.instance.toggleLayerGroup(LayerGroups.zones, this.currentActiveLayers[LayerGroups.zones], !this.currentActiveLayers[LayerGroups.zones]);
    });
  }

  //sets geojson shapes on map
  setShapes(shapes, editable = true, deleteCurrent = true, fitBounds = false) {
    let _shapes = shapes || [];
    this.instance.setShapes(_shapes, editable, deleteCurrent, fitBounds);
  }

  //Sets a route on the map
  setRoute(route) {
    if (!this.instance) return;

    let latLngs = [];
    if (route) {
      let encPolyline = route.encodedPolyline;

      if (encPolyline) {
        latLngs = googleService.decodePolyline(encPolyline);
      }

      if (!encPolyline && route.legs) {
        route.legs.forEach(l => {
          let {
            latitude: slat,
            longitude: slng
          } = l.startLocation.location;
          let {
            latitude: elat,
            longitude: elng
          } = l.endLocation.location;
          latLngs.push([slat, slng], [elat, elng]);
        });
      }
    }

    let polylines = [];
    let markers = [];

    if (latLngs.length) {
      polylines.push({
        latlngs: latLngs,
        options: {
          color: MapStyles.trail.colors.B,
          weight: 3,
          lineCap: 'round',
          opacity: 0.7
        }
      });

      markers.push({
        latlng: latLngs[0],
        options: {
          icon: this.getMapIcon({
            status: 'default'
          }),
          zIndex: 1001
        }
      });
    }

    this.instance.setPolylines(polylines, `${LayerGroups.route}-line`, true);
    this.instance.setMarkers(markers, LayerGroups.route, true);

    if (latLngs.length) {
      setTimeout(() => this.instance.fitBounds(latLngs));
    }

    return latLngs;
  }

  setETA(eta) {
    if (!this.instance) return;

    const polylines = [];
    const latlngs = eta ? googleService.decodePolyline(eta.polyline) : [];

    if (latlngs.length) {
      polylines.push({
        latlngs: latlngs,
        options: {
          color: '#617D8B',
          weight: 8,
          opacity: 0.5,
          zIndex: 0
        }
      });
    }
    this.instance.setPolylines(polylines, 'eta', true);
  }

  //starts drawing new shape on map
  newShape(type) {
    this.instance.newShape(type);
  }

  //returns shapes geojson on map
  getShapes(clearCurrent = true) {
    return this.instance.getShapes(clearCurrent);
  }

  shapesToArray(shapes) {
    //convert geojson shapes to latlng array
    if (!shapes || !shapes.length) return [];

    let shapesLatlng = shapes.map(s => {
      if (!s) return;

      let coords = s.geometry.coordinates;

      if (s.properties.radius) return [coords[1], coords[0]];

      let latlngs = coords.map(subc => subc.map(c => [c[1], c[0]]));
      return [].concat.apply([], latlngs);
    });

    return [].concat.apply([], shapesLatlng);
  }

  setLocationMarker(location) {
    let markers = [];
    if (location) {
      markers.push({
        latlng: location,
        options: {
          icon: {
            url: MapIcons.get('location'),
            size: [8, 8]
          }
        }
      });
    }
    this.instance.setMarkers(markers, 'location', true);
  }

  panTo(lat, lng, zoom) {
    if (this._hasImpl('panTo')) {
      this.instance.setView(lat, lng, zoom);
    }
  }

  getZoom() {
    if (this._hasImpl('getZoom')) {
      return this.instance.getZoom();
    }
  }

  setZoom(zoom) {
    if (this._hasImpl('setZoom')) {
      this.instance.setZoom(zoom);
    }
  }

  fitBounds(latlngs = []) {
    if (this._hasImpl('fitBounds')) {
      this.instance.fitBounds(latlngs.filter(x => x));
    }
  }

  fitMapMarkers(markers) {
    if (this._hasImpl('fitBounds') && markers.length) {
      let bounds = markers.map(m => m.latlng);
      this.instance.fitBounds(bounds);
    }
  }

  /**
   * TODO: Refactor - it's old!
   *
   * Takes a list of telemetry points and splits into sections used to draw the polylines
   *
   * @param {Telemetry[]} telemetry
   * @return {Array<Object>} tripDetails
   * @desc
   * TripDetails is an object with format:
   * ```{
   *  level: 'R|B|G|O', //used to color the polyline
   *  latlngs: Array[{lat, lng}] //every point for each section
   *  waypoints: Array[{lat, lng}] //points to place waypoint markers (the directional arrow)
   * }```
   */
  calcTripsDetails(telemetry) {
    let tripDetails = [];
    let idx = -1;

    telemetry.forEach((p, i) => {
      let level = p.speedLevel;

      //Created a new section if the speedlevel changed
      if (idx < 0 || tripDetails[idx].level !== level) {
        tripDetails.push({
          level: level,
          latlngs: [],
          waypoints: []
        });

        let j = Math.max(0, idx);
        tripDetails[j].latlngs.push(p.latlng);
        tripDetails[j].latlngs = p.interpolated ?
          tripDetails[j].latlngs.concat(p.interpolated) :
          tripDetails[j].latlngs;
        tripDetails[j].waypoints.push(p);
        idx++;
      } else {
        let wp = tripDetails[idx].waypoints || [];
        let latlngLen = tripDetails[idx].latlngs.length;

        if (latlngLen > 2 && !wp.length) {
          tripDetails[idx].waypoints.push(p);
        }
        if (i === telemetry.length - 1 && (wp.length <= 1 && latlngLen > 1)) {
          tripDetails[idx].waypoints.push(p);
        }
      }

      tripDetails[idx].latlngs.push(p.latlng);
      tripDetails[idx].latlngs = p.interpolated ?
        tripDetails[idx].latlngs.concat(p.interpolated) :
        tripDetails[idx].latlngs;

      let directionOffset = 20;

      let _idx = Math.min(idx || 0);
      let waypoints = tripDetails[_idx].waypoints;

      // if events exist
      if (p.events) {
        tripDetails[_idx].waypoints.push(p);
      }

      // detect change in direction add a new wayppoint
      if (waypoints) {
        let lastwp = waypoints[waypoints.length - 1] || {};

        let passDir = Math.abs(lastwp.heading - p.heading) > directionOffset;
        let passDist = !p.distance || p.distance > 100;

        if (passDir && passDist) {
          tripDetails[_idx].waypoints.push(p);
        }
      }
    });

    return tripDetails;
  }

  findLastTripEvent(tripdetails) {
    let len = tripdetails.length - 1;
    let hasParkedEvent = wp => {
      return wp.firstEventId === TelemetryEvents.parked;
    };

    let recentIdx;
    for (let i = len; i >= 0; i--) {
      let wp = tripdetails[i].waypoints;
      if (wp && wp.some(hasParkedEvent)) {
        recentIdx = i;
        break;
      }
    }

    if (recentIdx) {
      for (let i = 0; i < recentIdx; i++) {
        tripdetails[i].oldTrip = true;
        (tripdetails[i].waypoints || []).forEach(wp => (wp.oldTrip = true));
      }
    }

    return tripdetails;
  }

  getMapIcon(point) {
    let type = point.assetType == AssetTypes.mobile ? 'mobile' : 'car';
    let _url =
      MapIcons.get(`${type}_${point.status}`) ||
      MapIcons.get(`${type}_stopped`);

    let _size = 28;
    let startPoint = point._name === 'start';
    let endPoint = point._name === 'end';

    if (point._name) {
      _url = MapIcons.get(point._name) || _url;
    }

    if (point._alert) {
      _url = MapIcons.get(point._alert) || MapIcons.get('alert');
    }

    if (!point._name && point.heading !== undefined) {
      _url = MapIcons.get('waypoint');
      _size = 14;
    }

    let event = point.firstEventId;
    if (!startPoint && !endPoint && event) {
      _url = MapIcons.get(event) || MapIcons.get('alert');
    }

    if (point._alert || point.isIgnition && !endPoint) {
      _size = 22;
    }

    //
    _size = _size * (point.oldTrip ? 0.75 : 1);
    return {
      url: _url,
      size: [_size, _size]
    };
  }

  renderTooltip(marker, type) {
    let indicators;

    let types = {
      fleet: this.createFleetTooltip,
      poi: this.createPOITooltip,
      zones: this.createZoneTooltip,
      trail: this.createTrailTooltip
    };

    let fn = types[type];
    if (fn) {
      indicators = fn.call(this, marker);
      return TooltipService.create(indicators, type);
    }

    return null;
  }

  createTooltipIndicator(label, value = '', unit = '', cssClass = '') {
    return {
      label: label,
      // label,
      value,
      unit,
      cssClass
    };
  }

  convertAssetToMarker(asset) {
    let showLabel = this.uiService.userSettings.mapAssetLabels;
    return {
      latlng: asset.latlng,
      options: {
        icon: this.getMapIcon(asset),
        rotation: 0,
        opacity: asset.oldTrip ? 0.9 : 1,
        clickable: true,
        zIndex: 900,
        label: showLabel ? asset.name : null
      },
      metadata: {
        type: 'fleet',
        data: asset
      },
      tooltip: this.renderTooltip(asset, 'fleet')
    };
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * @param {Telemetry} marker
   */
  createTrailTooltip(marker) {
    let indicators = [];
    let speed = ConvertUtils.convertUnit('speed', marker.speed || 0, '0');

    if (marker.alert) {
      let name = this._translationService.getCap(marker.firstEventId);
      indicators.push(
        this.createTooltipIndicator(
          this._translationService.getCap('alert'),
          name,
          null,
          'alert bold'
        )
      );
    }

    if (marker.parked || marker.idling) {
      let duration = DateTimeUtils.duration(marker.firstEvent.duration);
      indicators.push(
        this.createTooltipIndicator(
          this._translationService.getCap('duration'),
          duration,
          null,
          'alert'
        ),
        this.createTooltipIndicator(
          null,
          marker.firstEvent.formattedAddress,
          null,
          'full address left'
        )
      );
    }

    if (marker.overspeeding) {
      const hasSpeedAlert = marker.firstEventId === AlertTypes.overspeed;
      //WEB-342 - if a speed alert exists, the speeding information will be shown by the alert only
      if (!hasSpeedAlert) {
        let speedLimit = ConvertUtils.convertUnit('speed', marker.speedLimit);
        let diff = (speed.value - speedLimit.value).toFixed(1);
        let msg = `${diff} ${
          speedLimit.unit.name
        } ${this._translationService.get('over_limit')}`;
        //prettier-ignore
        indicators.push(
          this.createTooltipIndicator(null, msg, null, 'full right overspeeding')
        );
      }
    }

    if (marker.ecodriving) {
      let event = this._translationService.getCap(marker.ecodriving.ecoEventId);
      indicators.push(
        this.createTooltipIndicator(event, null, null, 'alert bold')
      );
    }

    if (!marker.parked && !marker.idling) {
      indicators.push(
        this.createTooltipIndicator(
          this._translationService.get('speed'),
          speed.value,
          speed.unit.name
        )
      );
    }
    if (!marker.parked) {
      indicators.push(
        this.createTooltipIndicator(
          this._translationService.get('heading'),
          marker.headingCardinal
        )
      );
    }

    if (marker.gpsLocalCalendar) {
      indicators.push(
        this.createTooltipIndicator(
          null,
          marker.gpsLocalCalendar,
          null,
          'full bold'
        )
      );
    }

    return indicators;
  }

  createFleetTooltip(marker) {
    let indicators = [];

    let distance = marker.currentOdometer || 0;
    //If used any other time like this, refactor!
    distance = marker.odometerUnit ?
      ConvertUtils.convert(marker.odometerUnit, distance, true, true) :
      ConvertUtils.convertUnit('distance', distance, true, true);

    let settings = this.uiService.userSettings;
    indicators.push(
      this.createTooltipIndicator(null, marker.name, null, 'full bold left')
    );

    if (marker.address && settings.mapTooltipAddress) {
      indicators.push(
        this.createTooltipIndicator(
          null,
          marker.address,
          null,
          'full address left'
        )
      );
    }

    if (marker.snapshot && marker.snapshot.driverName) {
      indicators.push(
        this.createTooltipIndicator(
          'driver',
          marker.snapshot.driverName,
          null
        )
      );
    }

    if (settings.mapTooltipOdometer) {
      indicators.push(
        this.createTooltipIndicator(
          'odometer',
          distance.value,
          distance.unit.name
        )
      );
    }

    if (marker.currentChronometer && settings.mapTooltipChronometer) {
      let chrono = marker.currentChronometer.toFixed(1);
      indicators.push(
        this.createTooltipIndicator('chronometer', `${chrono} h`)
      );
    }

    if (marker.speed && settings.mapTooltipSpeed) {
      let speed = ConvertUtils.convertUnit('speed', marker.speed, '0');
      indicators.push(
        this.createTooltipIndicator(
          this._translationService.get('speed'),
          speed.value,
          speed.unit.name
        )
      );
    }

    let status = this._translationService.getCap(`status_${marker.status}`);
    let duration = DateTimeUtils.durationtime(marker.stateDuration);

    indicators.push(this.createTooltipIndicator(status, duration, null));

    if (marker.lastCommCalendar) {
      indicators.push(
        this.createTooltipIndicator(
          null,
          marker.lastCommCalendar,
          null,
          'full bold'
        )
      );
    }

    return indicators;
  }

  createPOITooltip(marker) {
    let indicators = [];
    indicators.push(
      this.createTooltipIndicator(null, marker.name, null, 'full bold left')
    );
    return indicators;
  }

  createZoneTooltip(zone) {
    let indicators = [];
    if (zone) {
      indicators.push(
        this.createTooltipIndicator(null, zone.name, null, 'full bold left')
      );
    }
    return indicators;
  }

  getMapTiles() {
    if (this._hasImpl('getMapTiles')) {
      let currId = (this.currentTileLayer || {}).id;
      return this.instance.getMapTiles().map(t => {
        return {
          ...t,
          active: t.id === currId
        };
      });
    }
  }

  //todo: refactor
  getLayerGroups() {
    //move this somwhere it makes sense
    if (this.currentActiveLayers[LayerGroups.poi] === undefined)
      this.currentActiveLayers[LayerGroups.poi] = true;

    return [{
        id: LayerGroups.poi,
        img: MapIcons.get('layer_places'),
        name: 'places',
        isGroup: true
      },
      {
        id: LayerGroups.zones,
        img: MapIcons.get('layer_zones'),
        name: 'zones',
        isGroup: true
      }
    ];
  }

  getMapLayers() {
    if (this._hasImpl('getMapLayers')) {
      let baseLayers = this.instance.getMapLayers() || [];
      let layerGroups = this.getLayerGroups();
      let layers = layerGroups.concat(baseLayers);

      layers.forEach(l => (l.active = !!this.currentActiveLayers[l.id]));

      return layers;
    }
  }

  toggleMapLayer(layer) {
    let show = this.currentActiveLayers[layer.id];
    this.setMapLayer(layer, !show);
  }

  setMapLayer(layer, show, persist = true) {
    if (this._hasImpl('toggleMapLayer') && layer) {
      show = layer.isGroup ?
        this.instance.toggleLayerGroup(layer.id, show, true) :
        this.instance.toggleMapLayer(layer.id);

      this.currentActiveLayers[layer.id] = show;

      //persist locally
      if (persist)
        this.storageService.set(
          MAP_LOCALSTORAGE_KEYS.activeLayers,
          this.currentActiveLayers
        );
    }
  }

  changeMapTiles(id) {
    if (this._hasImpl('changeMapTiles')) {
      this.currentTileLayer = this.instance.changeMapTiles(id);
    }
  }

  _hasImpl(fnName) {
    if (!this.instance) {
      LOG.warn(`map instance is null: [${this.instance}]. ${fnName} invalid`);
      return false;
    }
    let impl = this.instance[fnName];
    if (impl && typeof impl === 'function') {
      return true;
    }
    LOG.warn(
      `${fnName} not implemented in this map instance: [${this.instance}]`
    );
    return false;
  }
}
